import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Group,
  Button,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { fetchToken } from "../services/whatDrinkCanIMake.Api/identity";
import { ReqToken } from "../types";
import { useAuth } from "../auth/AuthContext";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function LoginForm() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>();

  async function getToken(userLogin: ReqToken) {
    try {
      const res = await fetchToken(userLogin);
      login(res.token);
      navigate("/");
    } catch (e) {
      setError(
        "Could not fetch token. Please contact support services for more assistance"
      );
      console.error("Error searching recipes:", e);
    }
  }

  const form = useForm<ReqToken>({
    initialValues: {
      email: "",
      password: "",
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  return (
    <>
      <Text c="red">{error}</Text>

      <form onSubmit={form.onSubmit((values) => getToken(values))}>
        <TextInput
          label="Email"
          placeholder="user@example.com"
          required
          {...form.getInputProps("email")}
        />
        <PasswordInput
          label="Password"
          placeholder="Your password"
          required
          mt="md"
          {...form.getInputProps("password")}
        />
        <Group justify="space-between" mt="lg">
          <Checkbox label="Remember me" />
          <Anchor c={"primary"} component="button" size="sm">
            Forgot password?
          </Anchor>
        </Group>
        <Button color="primary" type="submit" fullWidth mt="xl">
          Sign in
        </Button>
      </form>
    </>
  );
}

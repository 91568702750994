import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

interface props {
  children: ReactNode;
}

interface AuthContextType {
  isAuthenticated: boolean;
  token: string | null;
  login: (newToken: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  token: null,
  login: () => {},
  logout: () => {},
});

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider: React.FC<props> = ({ children }) => {
  const [token, setToken] = useState<string | null>(() =>
    sessionStorage.getItem("token")
  );

  const isAuthenticated = token != null ? true : false;

  const login = (newToken: string) => {
    sessionStorage.setItem("token", newToken);
    setToken(newToken);
  };

  const logout = () => {
    sessionStorage.removeItem("token");
    setToken(null);
  };

  useEffect(() => {
    const handleStorage = (event: StorageEvent) => {
      if (event.key === "token" && event.newValue == null) {
        setToken(null);
      }
    };
    var t = sessionStorage.getItem("token");
    window.addEventListener("storage", handleStorage);

    return () => {
      window.removeEventListener("storage", handleStorage);
    };
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

import { ReqRecipe, ResRecipe } from "../../types";

export async function SearchRecipes(ingredients: string): Promise<ResRecipe[]> {
  try {
    const recipesRoute = process.env.REACT_APP_API_URL + "Recipes/";

    const endpoint = `Search?ingredients=${ingredients}`;

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await fetch(recipesRoute + endpoint, requestOptions);
    if (!response.ok) {
      throw new Error("Unable to fetch recipes");
    }
    const recipes: ResRecipe[] = await response.json();
    return recipes;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

export async function GetRecipe(id: string): Promise<ResRecipe> {
  const recipesRoute = process.env.REACT_APP_API_URL + "Recipes/";

  const endpoint = id;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response: Response = await fetch(
    recipesRoute + endpoint,
    requestOptions
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch recipe: ${response.status}}`);
  }
  const recipe: ResRecipe = await response.json();
  return recipe;
}

export async function GetRecipes(): Promise<ResRecipe[]> {
  const recipesRoute = process.env.REACT_APP_API_URL + "Recipes";

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const response: Response = await fetch(recipesRoute, requestOptions);

  if (!response.ok) {
    throw new Error(`Failed to fetch recipe: ${response.status}}`);
  }
  const recipe: ResRecipe[] = await response.json();
  return recipe;
}

export async function CreateRecipe(dto: ReqRecipe, token: string) {
  const recipesRoute = process.env.REACT_APP_API_URL + "Recipes/";

  const formData = new FormData();

  formData.append("name", dto.name);
  formData.append("description", dto.description);
  formData.append("steps", dto.steps);
  if (dto.image) formData.append("image", dto.image);

  if (dto.tags) {
    dto.tags.forEach((tag, index) => {
      formData.append(`Tags[${index}]`, tag);
    });
  }

  dto.ingredients.forEach((ingredient, index) => {
    formData.append(`Ingredients[${index}].Name`, ingredient.name);
    formData.append(
      `Ingredients[${index}].Amount`,
      ingredient.amount.toString()
    );
    formData.append(`Ingredients[${index}].Unit`, ingredient.unit);
    if (ingredient.specificName)
      formData.append(
        `Ingredients[${index}].SpecificName`,
        ingredient.specificName
      );
  });

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  };

  const response = await fetch(recipesRoute, options);

  if (!response.ok) {
    throw new Error(`Failed to upload Recipe: ${response.status}}`);
  }
}

export async function UpdateRecipe(
  dto: ReqRecipe,
  token: string,
  recipeId: string
) {
  const recipesRoute = process.env.REACT_APP_API_URL + `Recipes/${recipeId}`;

  const formData = new FormData();

  formData.append("name", dto.name);
  formData.append("description", dto.description);
  formData.append("steps", dto.steps);
  if (dto.image) formData.append("image", dto.image);

  if (dto.tags) {
    dto.tags.forEach((tag, index) => {
      formData.append(`Tags[${index}]`, tag);
    });
  }

  dto.ingredients.forEach((ingredient, index) => {
    formData.append(`Ingredients[${index}].Name`, ingredient.name);
    formData.append(
      `Ingredients[${index}].Amount`,
      ingredient.amount.toString()
    );
    formData.append(`Ingredients[${index}].Unit`, ingredient.unit);
    if (ingredient.specificName)
      formData.append(
        `Ingredients[${index}].SpecificName`,
        ingredient.specificName
      );
  });

  const options = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  };

  const response = await fetch(recipesRoute, options);

  if (!response.ok) {
    throw new Error(`Failed to upload Recipe: ${response.status}}`);
  }
}

export async function DeleteRecipe(
  recipeId: string,
  token: string
): Promise<string> {
  const recipesRoute = process.env.REACT_APP_API_URL + "Recipes/";

  const requestOptions = {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response: Response = await fetch(
    recipesRoute + recipeId,
    requestOptions
  );

  if (!response.ok) {
    throw new Error(`Failed to Delete Recipe: ${response.status}`);
  }

  const r = await response.text();
  return r;
}

import { useEffect, useState } from "react";
import { GetRecipe } from "../services/whatDrinkCanIMake.Api/recipes";
import { GetImage } from "../services/whatDrinkCanIMake.Api/image";
import { ResRecipe } from "../types";
import { useParams } from "react-router-dom";
import {
  Stack,
  Paper,
  Group,
  Text,
  Image,
  Flex,
  rem,
  Pill,
  List,
  Center,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import { IconStar, IconPhoto } from "@tabler/icons-react";
import {
  Shake,
  Stir,
  Build,
  Blend,
  DryShake,
  ShakerStrain,
  Serve,
} from "../animations/animations";

export default function Recipe() {
  let { recipeId } = useParams<string>();
  const [recipe, setRecipe] = useState<ResRecipe>();
  const [image, setImage] = useState<string | null>();

  useEffect(() => {
    FetchRecipe();
  }, []);

  const FetchRecipe = async () => {
    try {
      if (!recipeId) return;
      var recipe = await GetRecipe(recipeId);
      setRecipe(recipe);

      if (!recipe.imageName) return;
      var image = await GetImage(recipe?.imageName);
      setImage(image);
    } catch (error) {
      console.error("Error searching recipes:", error);
    }
  };

  const StarRecipe = async () => {};

  return (
    <>
      <Paper flex={1} shadow="sm" radius="md">
        <Carousel
          p="xl"
          controlsOffset="xs"
          withIndicators
          flex={1}
          style={{ justifyContent: "space-between" }}
          h="100%"
        >
          <Carousel.Slide>
            <Flex
              mih={50}
              gap="xl"
              justify="space-between"
              direction="column"
              wrap="nowrap"
            >
              <Stack>
                {!image && (
                  <Center h={300} bg="var(--mantine-color-gray-light)">
                    <IconPhoto />
                  </Center>
                )}
                {image && (
                  <Center h={300} bg="var(--mantine-color-gray-light)">
                    <Image src={image} height={300} alt="image" />
                  </Center>
                )}

                <Stack gap={5}>
                  <Group justify="space-between" mt="lg">
                    <Stack gap={5}>
                      <Text fw={500} fz="lg">
                        {recipe?.name}
                      </Text>
                      <Text fz="sm" c="dimmed">
                        By{" "}
                        {`${recipe?.author.firstName} ${recipe?.author.lastName}`}
                      </Text>
                    </Stack>

                    <Group gap={5}>
                      <IconStar
                        onClick={StarRecipe}
                        style={{ width: rem(16), height: rem(16) }}
                      />
                      <Text fz="xs" fw={500}>
                        {recipe?.rating}
                      </Text>
                    </Group>
                  </Group>

                  <Text fz="sm" mt="sm">
                    {recipe?.description}
                  </Text>
                </Stack>

                <Stack>
                  <Text fw={500}>Ingredients</Text>
                  <Paper p={"xs"} withBorder>
                    {recipe?.ingredients.map((i, key) => (
                      <Group key={key} gap={5}>
                        <Text>
                          {`${i.amount} ${i.unit} of ` +
                            `${i.specificName != null ? i.specificName : ""}` +
                            ` ${i.name}`}
                        </Text>
                      </Group>
                    ))}
                  </Paper>

                  <div>
                    <Text p={"xs"} fz="sm" mt="sm">
                      Tags
                    </Text>

                    <Group>
                      {recipe?.tags?.map((tag, key) => (
                        <div key={key}>
                          <Pill c={"white"} bg="primary">
                            {tag}
                          </Pill>
                        </div>
                      ))}
                    </Group>
                  </div>
                </Stack>
              </Stack>
            </Flex>
          </Carousel.Slide>

          {/*_______________________________ Shake _______________________________*/}
          {recipe?.steps === "Shake" && (
            <>
              <Carousel.Slide
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                <Stack
                  h={300}
                  bg="var(--mantine-color-body)"
                  align="stretch"
                  justify="center"
                  gap="md"
                >
                  <Text size="xl" fw={900}>
                    Add the following to the shaker{" "}
                  </Text>
                  <List>
                    {recipe.ingredients.map((ingredient, index) => (
                      <List.Item key={index}>
                        {ingredient.amount} {ingredient.unit} {ingredient.name}
                      </List.Item>
                    ))}{" "}
                    <List.Item> 1 cup of ice </List.Item>
                  </List>

                  <Shake />
                </Stack>
              </Carousel.Slide>

              <Carousel.Slide
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                <Stack
                  h={300}
                  bg="var(--mantine-color-body)"
                  align="stretch"
                  justify="center"
                  gap="md"
                >
                  <Text size="xl" fw={900}>
                    Shake for 15 seconds
                  </Text>
                  <Shake />
                </Stack>
              </Carousel.Slide>

              <Carousel.Slide
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                <Stack
                  h={300}
                  bg="var(--mantine-color-body)"
                  align="stretch"
                  justify="center"
                  gap="md"
                >
                  <Text size="xl" fw={900}>
                    Strain ingredients
                  </Text>
                  <ShakerStrain />
                </Stack>
              </Carousel.Slide>
            </>
          )}
          {/*_______________________________ Shake _______________________________*/}

          {/*_______________________________ Dry Shake _______________________________*/}
          {recipe?.steps === "DryShake" && (
            <>
              <Carousel.Slide
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                <Stack
                  h={300}
                  bg="var(--mantine-color-body)"
                  align="stretch"
                  justify="center"
                  gap="md"
                >
                  <Text size="xl" fw={900}>
                    Add the following to the shaker
                    <List>
                      {recipe.ingredients.map((ingredient, index) => (
                        <List.Item key={index}>
                          {ingredient.amount} {ingredient.unit}{" "}
                          {ingredient.name}
                        </List.Item>
                      ))}{" "}
                      <List.Item> 1 cup of ice </List.Item>
                    </List>
                  </Text>

                  <DryShake />
                </Stack>
              </Carousel.Slide>

              <Carousel.Slide
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                <Stack
                  h={300}
                  bg="var(--mantine-color-body)"
                  align="stretch"
                  justify="center"
                  gap="md"
                >
                  <Text
                    size="xl"
                    fw={900}
                    variant="gradient"
                    gradient={{ from: "blue", to: "cyan", deg: 90 }}
                  >
                    Shake for 15 seconds
                  </Text>
                  <Shake />
                </Stack>
              </Carousel.Slide>

              <Carousel.Slide
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                <Stack
                  h={300}
                  bg="var(--mantine-color-body)"
                  align="stretch"
                  justify="center"
                  gap="md"
                >
                  <Text
                    size="xl"
                    fw={900}
                    variant="gradient"
                    gradient={{ from: "blue", to: "cyan", deg: 90 }}
                  >
                    Strain ingredients
                  </Text>
                  <ShakerStrain />
                </Stack>
              </Carousel.Slide>
            </>
          )}
          {/*_______________________________ Dry Shake _______________________________*/}

          {/*_______________________________ Shake _______________________________*/}
          {recipe?.steps === "Stir" && (
            <>
              <Stir />
            </>
          )}
          {/*_______________________________ Shake _______________________________*/}

          {/*_______________________________ Build _______________________________*/}
          {recipe?.steps === "Build" && (
            <>
              <Build />
            </>
          )}

          {/*_______________________________ Build _______________________________*/}

          {/*_______________________________ Blend _______________________________*/}
          {recipe?.steps === "Blend" && (
            <>
              <Blend />
            </>
          )}
          {/*_______________________________ Blend _______________________________*/}

          <Carousel.Slide
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
              gap: "30px",
            }}
          >
            <Stack
              h={300}
              bg="var(--mantine-color-body)"
              align="stretch"
              justify="center"
              gap="md"
            >
              <Text size="xl" fw={900}>
                Serve
              </Text>
              <Serve />
            </Stack>
          </Carousel.Slide>
        </Carousel>
      </Paper>
    </>
  );
}

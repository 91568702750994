import { Anchor, Paper, Title, Text, Container } from "@mantine/core";
import LoginForm from "../forms/LoginForm";

export default function Login() {
  return (
    <>
      <Container size={420} my={40}>
        <Title ta="center">Welcome back!</Title>
        <Text c="dimmed" size="sm" ta="center" mt={5}>
          Don't have an account yet?{" "}
          <Anchor c="primary" href="./signup" target="_blank" >
            Create account
          </Anchor>
        </Text>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <LoginForm />
        </Paper>
      </Container>
    </>
  );
}

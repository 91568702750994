export const CanBeSpecific = [
  "Vodka",
  "Gin",
  "Rum",
  "Tequila",
  "Whiskey",
  "Brandy",
  "Scotch",
  "Bourbon",
  "Cognac",
  "Triple sec",
  "Curacao",
];

import {
    TextInput,
    PasswordInput,
    Checkbox,
    Anchor,
    Group,
    Button,
    Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { CreateUser } from "../services/whatDrinkCanIMake.Api/users";
import { ReqUser } from "../types";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function SignUp() {
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>();

    async function insertUser(newUser: ReqUser) {
        try {
            const res = await CreateUser(newUser);
            navigate("/");
        } catch (e) {
            setError(
                "Could not create user. Please contact support services for more assistance"
            );
            console.error("Error searching recipes:", e);
        }
    }

    const form = useForm<ReqUser>({
        initialValues: {
            email: "",
            firstName: "",
            lastName: "",
            password: "",
        },

        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
        },
    });

    return (
        <>
            <Text c="red">{error}</Text>

            <form onSubmit={form.onSubmit((values) => insertUser(values))}>
                <TextInput
                    label="First Name"
                    placeholder="Adam"
                    required
                    {...form.getInputProps("firstName")}
                />
                <TextInput
                    label="Last Name"
                    placeholder="Smith"
                    required
                    {...form.getInputProps("lastName")}
                />
                <TextInput
                    label="Email"
                    placeholder="user@example.com"
                    required
                    {...form.getInputProps("email")}
                />
                <PasswordInput
                    label="Password"
                    placeholder="Your password"
                    required
                    mt="md"
                    {...form.getInputProps("password")}
                />
               
                <Button color="primary" type="submit" fullWidth mt="xl">
                    Sign up
                </Button>
            </form>
        </>
    );
}

import { ReqToken, ReqUser, ResRecipe, ResToken } from "../../types";

const userRoute = process.env.REACT_APP_API_URL + "Users/";

export async function GetUserRecipes(token: string): Promise<ResRecipe[]> {
  const endpoint = "Recipes";

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response: Response = await fetch(userRoute + endpoint, requestOptions);

  if (!response.ok) {
    throw new Error(`Failed to fetch token: ${response.status}}`);
  }

  const recipes: ResRecipe[] = (await response.json()) as ResRecipe[];
  return recipes;
}

export async function CreateUser(newUser: ReqUser): Promise<string> {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newUser),
  };

  const response: Response = await fetch(userRoute, requestOptions);

  if (!response.ok) {
    throw new Error(`Failed to insert user: ${response.status}}`);
  }

  var message = (await response.json()) as string;
  return message;
}

import { ReqToken, ResToken } from "../../types";

export async function GetImage(imageId: string): Promise<string | null> {
  const imageRoute = process.env.REACT_APP_API_URL + "Image/";

  const endpoint = `?imageName=${imageId}`;

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "text/string",
    },
  };

  const response: Response = await fetch(imageRoute + endpoint, requestOptions);

  if (!response.ok) {
    throw new Error(`Failed to fetch token: ${response.status}}`);
  }

  const image = await response.text();
  return `data:image/png;base64,${image}`;
}

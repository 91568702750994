import { Menu, Button, Text, rem } from "@mantine/core";
import { IconSettings, IconLogout, IconUser } from "@tabler/icons-react";
import { useAuth } from "../auth/AuthContext";
import { Avatar } from "@mantine/core";

export default function Profile() {
  const { logout, isAuthenticated } = useAuth();
  return (
    <Menu
      trigger="click-hover"
      openDelay={100}
      closeDelay={400}
      shadow="md"
      width={200}
      transitionProps={{ transition: "skew-up", duration: 150 }}
    >
      <Menu.Target>
        <Avatar radius="xl" />
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>User</Menu.Label>

        {!isAuthenticated && (
          <Menu.Item
            component="a"
            href="/login"
            leftSection={
              <IconSettings style={{ width: rem(14), height: rem(14) }} />
            }
          >
            Log in
          </Menu.Item>
        )}

        {isAuthenticated && (
          <Menu.Item
            component="a"
            href="/profile"
            leftSection={
              <IconUser style={{ width: rem(14), height: rem(14) }} />
            }
          >
            My Profile
          </Menu.Item>
        )}

        <Menu.Divider />

        {isAuthenticated && (
          <Menu.Item
            color="red"
            onClick={logout}
            leftSection={
              <IconLogout style={{ width: rem(14), height: rem(14) }} />
            }
          >
            Log out
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  );
}

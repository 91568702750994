import { Anchor, Paper, Title, Text, Container } from "@mantine/core";
import SignUpForm from "../forms/SignUp";

export default function SignUp() {
  return(
    <>
    <Container size={420} my={40}>
      <Title ta="center">Welcome to the club!</Title>
 

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <SignUpForm />
      </Paper>
    </Container>
  </>
  )
}

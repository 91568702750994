import { useNavigate, Outlet } from "react-router-dom";
import { MantineLogo } from "@mantinex/mantine-logo";
import {
  AppShell,
  Burger,
  Group,
  useMantineColorScheme,
  useComputedColorScheme,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import RootNav from "../nav/RootNav";
import Profile from "../components/Profile";
import { IconSun, IconMoon } from "@tabler/icons-react";
import { Logo, SmallLogo } from "../svgs";
import { useMediaQuery } from "@mantine/hooks";
export default function Root() {
  const [opened, { toggle }] = useDisclosure();
  const navigate = useNavigate();
  const { setColorScheme, clearColorScheme } = useMantineColorScheme();
  const matches = useMediaQuery("(min-width: 768px)");

  const computedColorScheme = useComputedColorScheme("light");
  return (
    <>
      <AppShell
        header={{ height: 100 }}
        navbar={{
          width: 300,
          breakpoint: "sm",
          collapsed: { mobile: !opened },
        }}
        padding="md"
      >
        <AppShell.Header>
          <Group justify="space-between" h="100%">
            <Group>
              <Burger
                opened={opened}
                onClick={toggle}
                hiddenFrom="sm"
                size="sm"
              />
              <div className="clickable" onClick={() => navigate("/")}>
                {matches ? <Logo /> : <SmallLogo />}
              </div>
            </Group>

            <Group>
              {computedColorScheme === "dark" && (
                <IconSun onClick={() => setColorScheme("light")} />
              )}

              {computedColorScheme === "light" && (
                <IconMoon onClick={() => setColorScheme("dark")} />
              )}
              <Profile />
            </Group>
          </Group>
        </AppShell.Header>

        <AppShell.Navbar p="md">
          <RootNav />
        </AppShell.Navbar>

        <AppShell.Main>
          <Outlet />
        </AppShell.Main>
      </AppShell>
    </>
  );
}

import { Text, Button, Group, Modal } from "@mantine/core";

interface DeleteModalProps {
  opened: boolean;
  open: () => void;
  close: () => void;
  onDelete: (Delete: any) => void;
}

export default function DeleteModal({
  opened,
  open,
  close,
  onDelete,
}: DeleteModalProps) {
  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        withCloseButton={true}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <Text mt="md" mb="xs">
          Are you sure you want to delete this?
        </Text>
        <Group justify="space-between" mt="md" mb="xs">
          <Button variant="filled" color="gray" onClick={close}>
            Cancel
          </Button>
          <Button variant="filled" color="red" onClick={onDelete}>
            Delete
          </Button>
        </Group>
      </Modal>
    </>
  );
}

export const CocktailIngredients = [
  "Vodka",
  "Gin",
  "Rum",
  "Tequila",
  "Whiskey",
  "Brandy",
  "Scotch",
  "Bourbon",
  "Cognac",
  "Absinthe",
  "Triple sec",
  "Kahlua",
  "Baileys Irish Cream",
  "Amaretto",
  "Cointreau",
  "Grand Marnier",
  "Champagne",
  "Prosecco",
  "Vermouth",
  "Campari",
  "Aperol",
  "Peach Schnapps",
  "Sloe Gin",
  "Pisco",
  "Sambuca",
  "Midori",
  "Cachaca",
  "Limoncello",
  "Frangelico",
  "Galliano",
  "Jägermeister",
  "Chambord",
  "Malibu Rum",
  "Chartreuse",
  "Curacao",
  "Creme de Menthe",
  "Creme de Cacao",
  "Bitters",
  "Honey",
  "Mint",
  "Basil",
  "Thyme",
  "Rosemary",
  "Cilantro",
  "Lemon Juice",
  "Lime Juice",
  "Orange",
  "Grapefruit",
  "Cranberry juice",
  "Orange juice",
  "Pineapple juice",
  "Lemonade",
  "Grenadine",
  "Coconut milk",
  "Cream",
  "Egg white",
  "Soda Water",
  "Ginger beer",
  "Tonic water",
  "Cola",
  "Sprite",
  "Coca-Cola",
  "Red Bull",
  "Gatorade",
  "Ice",
  "Mint leaves",
  "Cherry",
  "Olives",
  "Cucumber",
  "Celery",
  "Bacon",
  "Pickles",
  "Candy cane",
  "Coconut flakes",
  "Cinnamon stick",
  "Nutmeg",
  "Vanilla extract",
  "Coffee liqueur",
  "Cinnamon schnapps",
  "Ginger liqueur",
  "Apricot brandy",
  "Pomegranate liqueur",
  "Maraschino liqueur",
  "Raspberry liqueur",
  "Blackberry liqueur",
  "Blue Curacao",
  "Butterscotch schnapps",
  "Melon liqueur",
  "Peach liqueur",
  "Pear liqueur",
  "Strawberry liqueur",
  "Tropical fruit liqueur",
  "Whipped cream",
  // Common Syrup Flavors
  "Simple syrup",
  "Agave syrup",
  "Vanilla syrup",
  "Caramel syrup",
  "Hazelnut syrup",
  "Almond syrup",
  "Cinnamon syrup",
  "Ginger syrup",
  "Maple syrup",
  "Lavender syrup",
  "Rose syrup",
  "Orgeat syrup",
  "Grenadine syrup",
  "Raspberry syrup",
  "Passion fruit syrup",
  "Pineapple syrup",
  "Peach syrup",
  "Mint syrup",
  "Coconut syrup",
  "Lemon syrup",
  "Lime syrup",
  "Orange syrup",
  "Pomegranate syrup",
  "Chocolate syrup",
  "Blueberry syrup",
  "Blackberry syrup",
  "Apple syrup",
  "Cherry syrup",
  "Grapefruit syrup",
];

export const Tags = [
  "No Citrus",
  "Mocktail",
  "No Dairy",
  "No Egg",
  "Citrusy",
  "Tropical",
  "Sweet",
  "Sour",
  "Bitter",
  "Spicy",
  "Refreshing",
  "Fruity",
  "Creamy",
  "Herbal",
  "Boozy",
  "Classic",
  "Frozen",
  "Sparkling",
  "Signature",
  "Seasonal",
  "Low Alcohol",
  "High Alcohol",
  "Aromatic",
  "Exotic",
];

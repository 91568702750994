import { Modal, Button, Stack, Grid } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import MyRecipeCard from "../components/RecipeCard";
import { useEffect, useState } from "react";
import { GetUserRecipes } from "../services/whatDrinkCanIMake.Api/users";
import { ResRecipe } from "../types";
import { useAuth } from "../auth/AuthContext";
import { IconPlus } from "@tabler/icons-react";
import AddRecipeForm from "../forms/AddRecipeForm";
import DeleteModal from "../components/DeleteModal";
import { DeleteRecipe } from "../services/whatDrinkCanIMake.Api/recipes";
import RecipeCard from "../components/RecipeCard";

export default function MyRecipes() {
  const icon = <IconPlus size={14} />;
  const [
    addRecipeModalOpened,
    { open: openAddRecipeModal, close: closeAddRecipeModal },
  ] = useDisclosure(false);
  const [
    deleteModalOpened,
    { open: openDeleteModal, close: closeDeleteModal },
  ] = useDisclosure(false);

  const [editModalOpened, { open: openEditModal, close: closeEditModal }] =
    useDisclosure(false);

  const [recipes, setRecipes] = useState<ResRecipe[]>();
  const { token } = useAuth();
  const [error, setError] = useState<string | null>();
  const [recipeToBeDeleted, setRecipeToBeDeleted] = useState<ResRecipe>();
  const [recipeToBeEdited, setRecipeToBeEdited] = useState<ResRecipe>();

  useEffect(() => {
    const GetRecipes = async () => {
      FetchRecipes();
    };
    GetRecipes();
  }, [addRecipeModalOpened, deleteModalOpened, editModalOpened]);

  const FetchRecipes = async () => {
    try {
      if (token == null) return;
      setRecipes(await GetUserRecipes(token));
    } catch (error) {
      console.error("Error searching recipes:", error);
      setError("Error searching recipes");
    }
  };

  const Delete = async () => {
    try {
      if (token == null) {
        throw new Error("Token is null. Cannot delete recipe.");
      }
      if (!recipeToBeDeleted) {
        throw new Error("No recipe selected to delete.");
      }
      await DeleteRecipe(recipeToBeDeleted.id, token);
      closeDeleteModal();
    } catch (e) {
      console.error("Error deleting recipe:", e);
      setError("Could not delete recipe");
    }
  };

  return (
    <>
      {error}
      <Grid>
        {recipes?.length == 0 ? (
          <p>No recipes created</p>
        ) : (
          recipes?.map((recipe, key) => (
            <Grid.Col span={{ base: 12, md: 8, lg: 4 }}>
              <RecipeCard
                myRecipe={true}
                key={key}
                recipe={recipe}
                onDelete={(recipe) => {
                  setRecipeToBeDeleted(recipe);
                  openDeleteModal();
                }}
                onEdit={(recipe) => {
                  setRecipeToBeEdited(recipe);
                  openEditModal();
                }}
              />
            </Grid.Col>
          ))
        )}
      </Grid>

      <div style={{ position: "fixed", bottom: "15px", right: "25px" }}>
        <Button
          color="primary"
          onClick={openAddRecipeModal}
          rightSection={icon}
        >
          Add Recipe
        </Button>
      </div>

      {/*add modal*/}
      <Modal
        opened={addRecipeModalOpened}
        onClose={closeAddRecipeModal}
        withCloseButton={true}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <AddRecipeForm onSubmit={closeAddRecipeModal} />
      </Modal>

      {/*delete modal*/}
      <DeleteModal
        opened={deleteModalOpened}
        open={openDeleteModal}
        close={closeDeleteModal}
        onDelete={Delete}
      />

      {/*edit modal*/}
      <Modal
        opened={editModalOpened}
        onClose={closeEditModal}
        withCloseButton={true}
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <AddRecipeForm recipe={recipeToBeEdited} onSubmit={closeEditModal} />
      </Modal>
    </>
  );
}

import { NavLink } from "@mantine/core";
import { IconActivity, IconSearch } from "@tabler/icons-react";
import { useAuth } from "../auth/AuthContext";
export default function RootNav() {
  const { isAuthenticated } = useAuth();
  return (
    <>
      <NavLink
        href="/"
        label="Search Recipes"
        leftSection={<IconSearch size="1rem" stroke={1.5} />}
        variant="subtle"
        active
        color="primary"
      />

      {isAuthenticated && (
        <>
          <NavLink
            href="/myRecipes"
            label="My Recipes"
            leftSection={<IconActivity size="1rem" stroke={1.5} />}
            variant="subtle"
            active
            color="primary"
          />

          <NavLink
            href="/savedRecipes"
            label="Saved Recipes"
            leftSection={<IconActivity size="1rem" stroke={1.5} />}
            variant="subtle"
            active
            color="primary"
          />

          <NavLink
            href="/myMenus"
            label="My Menu's"
            leftSection={<IconActivity size="1rem" stroke={1.5} />}
            variant="subtle"
            active
            color="primary"
          />
        </>
      )}

      <NavLink
        href="/About"
        label="About"
        leftSection={<IconActivity size="1rem" stroke={1.5} />}
        variant="subtle"
        active
        color="primary"
      />
    </>
  );
}

import {
  TextInput,
  Button,
  Group,
  Text,
  Textarea,
  TagsInput,
  Stack,
  Autocomplete,
  Pill,
  Chip,
  Image,
  Center,
} from "@mantine/core";
import { useAuth } from "../auth/AuthContext";
import {
  CreateRecipe,
  UpdateRecipe,
} from "../services/whatDrinkCanIMake.Api/recipes";
import { FileButton, Select, Checkbox, Paper } from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import { useState, useEffect } from "react";
import { ReqRecipe, RecipeIngredients, ResRecipe } from "../types";
import { CocktailIngredients, Tags } from "../components/Recomendations";
import { CanBeSpecific } from "../components/SpecificIngredients";
import { GetImage } from "../services/whatDrinkCanIMake.Api/image";

const units = ["ml", "g", "unit"];

interface props {
  onSubmit: () => void;
  recipe?: ResRecipe;
}

const AddRecipeForm = ({ onSubmit, recipe }: props) => {
  const { token } = useAuth();
  const [error, setError] = useState<string | null>(null);

  const initialTitle = recipe?.name ?? "";
  const initialDescription = recipe?.description ?? "";
  const initialTags = recipe?.tags ?? undefined;
  const initialSteps = recipe?.steps ?? null;
  const initialIngredients = recipe?.ingredients ?? [];
  const initialImageName = recipe?.imageName ?? null;

  const [ingredients, setIngredients] =
    useState<RecipeIngredients[]>(initialIngredients);
  const [title, setTitle] = useState<string>(initialTitle);
  const [description, setDescription] = useState<string>(initialDescription);
  const [tags, setTags] = useState<string[] | undefined>(initialTags);
  const [steps, setSteps] = useState<string | null>(initialSteps);
  const [file, setFile] = useState<File | null>(null);
  const [fileEncoded, setFileEncoded] = useState<string | null>(null);
  const [selectedIngredient, setSelectedIngredient] = useState<string>("");
  const [ingredientAmount, setIngredientAmount] = useState<string>("");
  const [specificName, setSpecificName] = useState<string | null>(null);
  const [dontCare, setDontCare] = useState<boolean>(false);
  const [unit, setUnit] = useState<string | null>("");
  const TRANSIOTION_DURATION = 200;
  const [embla, setEmbla] = useState<any>(null);
  const handleAddIngredient = () => {
    if (selectedIngredient == "") {
      setError("Ingredient name cannot be nothing");
      return;
    }
    if (ingredientAmount == "") {
      setError("Ingredient amount cannot be nothing");
      return;
    }
    if (unit == "" || unit == null) {
      setError("Ingredient unit cannot be nothing");
      return;
    }

    const newIngredient: RecipeIngredients = {
      name: selectedIngredient,
      amount: parseInt(ingredientAmount),
      unit: unit,
      specificName: specificName,
    };

    setIngredients((prevIngredients) => [...prevIngredients, newIngredient]);
    setSelectedIngredient("");
    setIngredientAmount("");
    setSpecificName(null);
  };

  useEffect(() => {
    setTimeout(() => embla && embla.reInit(), TRANSIOTION_DURATION);
  }, [embla]);

  useEffect(() => {
    setDontCare(false);
    setSpecificName(null);
  }, [selectedIngredient]);

  const removeIngredient = (indexToRemove: number) => {
    setIngredients((prevIngredients) =>
      prevIngredients.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      if (steps == null) {
        setError("Please Select from the steps menu");
        return;
      }

      if (title == null) {
        setError("Please give your cocktial a name");
        return;
      }

      if (description == null) {
        setError("Please give your cocktial a description");
        return;
      }

      const dto: ReqRecipe = {
        name: title,
        description: description,
        ingredients: ingredients,
        tags: tags,
        image: file,
        steps: steps,
      };

      if (token == null) {
        setError("Please Login");
        return;
      }

      if (!recipe) {
        await CreateRecipe(dto, token);
      } else {
        await UpdateRecipe(dto, token, recipe.id);
      }
      onSubmit();
    } catch (e) {
      setError("Could not upload cocktail");
      console.log(e);
    }
  };

  useEffect(() => {
    getImage();
  }, []);

  const getImage = async () => {
    try {
      if (recipe?.imageName != null || recipe?.imageName != undefined) {
        const image = await GetImage(recipe?.imageName);
        setFileEncoded(image);
      }
    } catch (e: any) {
      console.log("Error getting image");
    }
  };

  useEffect(() => {
    const convertFileToBase64 = async () => {
      try {
        const base64Data = await toBase64();
        setFileEncoded(base64Data);
      } catch (error) {
        setError("Error converting file to Base64");
        console.error("Error converting file to Base64:", error);
      }
    };
    convertFileToBase64();
  }, [file]);

  const toBase64 = async (): Promise<string | null> => {
    if (!file) return null;

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        resolve(reader.result as string);
      };

      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack>
        <TextInput
          withAsterisk
          label="Title"
          placeholder="Daquri"
          onChange={(event) => setTitle(event.target.value)}
          value={title}
        />
        <Textarea
          label="Description"
          placeholder="A sour style cocktail made with 3 simple ingredients"
          onChange={(event) => setDescription(event.target.value)}
          value={description}
        />
        <Text c="red">{error}</Text>
      </Stack>

      <Carousel slideSize="80%" slideGap="xl" getEmblaApi={setEmbla}>
        <Carousel.Slide>
          <Stack>
            <Text>Ingredients</Text>

            <Paper
              withBorder={true}
              style={{ height: "150px", overflow: "auto" }}
              p="xs"
            >
              <Stack align="flex-start" justify="flex-start" p="xs">
                {ingredients &&
                  ingredients.map((ingredient, key) => (
                    <div key={key}>
                      <Pill
                        withRemoveButton
                        onRemove={() => {
                          removeIngredient(key);
                        }}
                      >
                        {`${ingredient.amount} ${ingredient.unit} | ${ingredient.name}` +
                          (ingredient.specificName != null
                            ? ` | ${ingredient.specificName}`
                            : "")}
                      </Pill>
                    </div>
                  ))}
              </Stack>
            </Paper>

            <Autocomplete
              label="Ingredient Name"
              placeholder="Lime juice"
              data={CocktailIngredients}
              value={selectedIngredient}
              onChange={(value) => setSelectedIngredient(value || "")}
            />

            {/* check if ingredient requires specific field*/}

            {CanBeSpecific.includes(selectedIngredient) && !dontCare && (
              <>
                <Group>
                  <TextInput
                    value={(specificName ?? "").toString()}
                    onChange={(event) =>
                      setSpecificName(event.currentTarget.value)
                    }
                    label="Specific Name"
                    placeholder="Smirnoff"
                  />
                  <Stack justify="flex-end">
                    <Button color={"red"} onClick={() => setDontCare(true)}>
                      Dont care
                    </Button>
                  </Stack>
                </Group>
              </>
            )}

            <Group>
              <TextInput
                value={(ingredientAmount ?? "").toString()}
                onChange={(event) =>
                  setIngredientAmount(event.currentTarget.value)
                }
                label="Amount"
                placeholder="30"
              />
              <Select
                value={unit}
                label="Units"
                data={units}
                onChange={setUnit}
              />
            </Group>

            <Button color={"primary"} onClick={() => handleAddIngredient()}>
              Add Ingredient
            </Button>
          </Stack>
        </Carousel.Slide>

        <Carousel.Slide>
          <Stack>
            <Text>Steps</Text>
            <Chip.Group
              multiple={false}
              value={steps}
              onChange={(val) => setSteps(val)}
            >
              <Group>
                <Chip color="primary" value="Shake">
                  Shake
                </Chip>
                <Chip color="primary" value="Stir">
                  Stir
                </Chip>
                <Chip color="primary" value="Blend">
                  Blend
                </Chip>
                <Chip color="primary" value="Build">
                  Build
                </Chip>
              </Group>
            </Chip.Group>
            {steps === "Shake" && (
              <Checkbox color={"primary"} label="Dry Shake?" />
            )}
          </Stack>
        </Carousel.Slide>

        <Carousel.Slide>
          <Text>Tags</Text>
          <TagsInput
            label="Press Enter to submit a tag"
            placeholder="Pick tag from list"
            data={Tags}
            value={tags}
            onChange={(val) => setTags(val)}
          />
        </Carousel.Slide>

        <Carousel.Slide
          p={"xl"}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Center>
            <Stack>
              {fileEncoded && <Image src={fileEncoded}></Image>}
              <FileButton onChange={(f) => setFile(f)} accept="image/png">
                {(props) => <Button {...props}>Upload image</Button>}
              </FileButton>
            </Stack>
          </Center>

          <Button
            color={"primary"}
            style={{ alignSelf: "flex-end" }}
            type="submit"
          >
            Submit
          </Button>
        </Carousel.Slide>
      </Carousel>
    </form>
  );
};

export default AddRecipeForm;

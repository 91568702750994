import { useEffect, useState } from "react";
import {
  Card,
  Image,
  Text,
  Center,
  Button,
  Group,
  Container,
  Paper,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { ResRecipe } from "../types";
import { IconEdit } from "@tabler/icons-react";
import { ActionIcon } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import { GetImage } from "../services/whatDrinkCanIMake.Api/image";

interface ChildProps {
  recipe: ResRecipe;
  myRecipe: boolean;
  onDelete?: (recipe: ResRecipe) => void;
  onEdit?: (recipe: ResRecipe) => void;
}

export default function RecipeCard({
  recipe,
  onDelete,
  onEdit,
  myRecipe,
}: ChildProps) {
  const navigate = useNavigate();
  const [image, setImage] = useState<string | null>(null);
  const [imageError, setImageError] = useState<string | null>(null);

  useEffect(() => {
    const getImage = async () => {
      try {
        if (recipe.imageName == null) {
          setImage(null);
          return;
        }
        const image = await GetImage(recipe.imageName);
        if (image == null) {
          setImage(null);
          return;
        }
        setImage(image);
      } catch (error) {
        setImageError("Could not Fetch recipe image");
        console.error("Error getting recipe image: ", error);
        setImage(null);
      }
    };
    getImage();
  }, []);

  return (
    <>
      <Card shadow="sm" padding="lg" radius="md" withBorder>
        <Card.Section>
          <Container p={6} size="xs">
            <Group justify="space-between" mt="md" mb="xs">
              <Text fw={500}>{recipe.name}</Text>
              {myRecipe && (
                <ActionIcon
                  variant="default"
                  aria-label="ActionIcon with size as a number"
                  onClick={() => onEdit!(recipe)}
                >
                  <IconEdit />
                </ActionIcon>
              )}
            </Group>

            <Text size="sm" c="dimmed">
              {recipe.description}
            </Text>
          </Container>
          <Container p={6} size="xs" h={300}>
            {!image && (
              <Center h={300} bg="var(--mantine-color-gray-light)">
                {!imageError && <Text c="red">{imageError}</Text>}
                <IconPhoto />
              </Center>
            )}
            {image && (
              <Center>
                <Image src={image} height={300} alt="image" />
              </Center>
            )}
          </Container>
        </Card.Section>

        <Card.Section inheritPadding mt="sm" pb="md">
          {recipe.ingredients.map((i) => (
            <>
              <Group>
                <Group gap={"1"}>
                  <Text size="sm" c="dimmed">
                    {i.amount}
                  </Text>
                  <Text size="sm" c="dimmed">
                    {i.unit}
                  </Text>
                </Group>
                <Text size="sm" c="dimmed">
                  {i.name}
                </Text>
              </Group>
            </>
          ))}

          <Group justify="space-between" mt="md" mb="xs">
            <Button
              onClick={() => navigate(`/Recipe/${recipe.id}`)}
              color="primary"
              mt="md"
              radius="md"
            >
              View Recipe
            </Button>

            {myRecipe && (
              <Button
                onClick={() => onDelete!(recipe)}
                color="red"
                mt="md"
                radius="md"
              >
                Delete
              </Button>
            )}
          </Group>
        </Card.Section>
      </Card>
    </>
  );
}

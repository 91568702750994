import React from "react";
import ReactDOM from "react-dom/client";
import {
  Route,
  createRoutesFromElements,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import Search from "./pages/Search";
import About from "./pages/About";
import User from "./pages/User";
import Error from "./pages/Error";
import MyRecipes from "./pages/MyRecipes";
import Profile from "./pages/Profile";
import Login from "./pages/Login";
import Recipe from "./pages/Recipe";

import RootLayout from "./layouts/RootLayout";
import { AuthProvider } from "./auth/AuthContext";

import { createTheme, MantineProvider, virtualColor } from "@mantine/core";
import { Protected } from "./auth/Protected";
import "@mantine/carousel/styles.css";
import "@mantine/core/styles.css";
import "@mantine/dropzone/styles.css";
import "./index.css";
import SignUp from "./pages/SignUp";
import MyMenus from "./pages/MyMenus";
import SavedRecipes from "./pages/SavedRecipes";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />} errorElement={<Error />}>
      <Route errorElement={<Error />}>
        <Route index element={<Search />} />
        <Route path="recipe/:recipeId" element={<Recipe />} />
        <Route path="/about" element={<About />} />{" "}
        <Route
          path="/user"
          element={
            <Protected>
              <User />
            </Protected>
          }
        />
      </Route>
      <Route
        path="/myRecipes"
        element={
          <Protected>
            <MyRecipes />
          </Protected>
        }
      />
      <Route
        path="/savedRecipes"
        element={
          <Protected>
            <SavedRecipes />
          </Protected>
        }
      />
      <Route
        path="/myMenus"
        element={
          <Protected>
            <MyMenus />
          </Protected>
        }
      />
      <Route
        path="/profile"
        element={
          <Protected>
            <Profile />
          </Protected>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
    </Route>
  )
);

const theme = createTheme({
  fontFamily: "Open Sans, sans-serif",
  colors: {
    primary: virtualColor({
      name: "primary",
      dark: "orange",
      light: "cyan",
    }),
    secondary: virtualColor({
      name: "secondary",
      dark: "orange",
      light: "cyan",
    }),
  },
});

root.render(
  <React.StrictMode>
    <AuthProvider>
      <MantineProvider defaultColorScheme="dark" theme={theme}>
        <RouterProvider router={router} />
      </MantineProvider>
    </AuthProvider>
  </React.StrictMode>
);

import { CocktailShaker, CocktialGlass, BarMan } from "../svgs";
import { Text, Stack } from "@mantine/core";
import "./animations.css";

export function Blend() {
  return <div>Blend</div>;
}

export function Build() {
  return <div>Build</div>;
}

export function Stir() {
  return <div>Stir</div>;
}

export function Shake() {
  return (
    <div className="jumpShake">
      <CocktailShaker height={"100%"} width={"100%"} />
    </div>
  );
}

export function Serve() {
  return (
    <div className="serve">
      <BarMan height={"50%"} width={"50%"} />
      <CocktialGlass height={"50%"} width={"50%"} />
    </div>
  );
}

export function ShakerStrain() {
  return (
    <div className="shakerStrain">
      <CocktailShaker height={"50%"} width={"50%"} />
      <CocktialGlass height={"50%"} width={"50%"} />
    </div>
  );
}

export function DryShake() {
  return (
    <>
      <div className="jumpShake">
        <CocktailShaker height={"100%"} width={"100%"} />
      </div>
    </>
  );
}

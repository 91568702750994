import { Text, TextInput, Grid, Button, Stack } from "@mantine/core";
import RecipeCard from "../components/RecipeCard";
import { useEffect, useState } from "react";
import {
  SearchRecipes,
  GetRecipes,
} from "../services/whatDrinkCanIMake.Api/recipes";
import { ResRecipe } from "../types";
import { MultiSelectCreatable } from "../components/MultiSelectCreatable";
import { CocktailIngredients } from "../components/Recomendations";

export default function Search() {
  const [recipes, setRecipes] = useState<ResRecipe[]>();
  const [value, setValue] = useState<string[]>();
  const [error, setError] = useState<string | null>(null);

  const Search = async () => {
    try {
      const valueAsString = value?.toString();
      if (valueAsString == null || valueAsString === "") {
        setError("Please add something to the search bar");
        return;
      }
      const recipesData = await SearchRecipes(valueAsString);
      setRecipes(recipesData);
      setError(null);
    } catch (error) {
      setError("Error searching recipes...");
      return;
    }
  };

  const fetchRecipes = async () => {
    try {
      const recipes = await GetRecipes();
      setRecipes(recipes);
    } catch (e) {
      setError("Error getting recipes...");
    }
  };

  useEffect(() => {
    const fetch = async () => {
      await fetchRecipes();
    };
    fetch();
  }, []);

  return (
    <>
      <Stack>
        <Stack>
          <MultiSelectCreatable
            label="Ingredients you have"
            placeholder="Search Ingredients"
            description="Input Ingredients to search our database of cocktail recipes"
            val={(val) => setValue(val)}
            initData={CocktailIngredients}
          />
          <Button bg="primary" onClick={Search}>
            Search
          </Button>
        </Stack>

        <Grid>
          {recipes?.length == 0 ? (
            <p>No recipes found</p>
          ) : (
            recipes?.map((recipe, key) => (
              <Grid.Col key={key} span={{ base: 12, md: 8, lg: 4 }}>
                <RecipeCard myRecipe={false} recipe={recipe} />
              </Grid.Col>
            ))
          )}
          <Text size="lg" c="red">
            {error}
          </Text>
        </Grid>
      </Stack>
    </>
  );
}

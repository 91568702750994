import { ReqToken, ResToken } from "../../types";

export async function fetchToken(dto: ReqToken): Promise<ResToken> {
  const identityroute = process.env.REACT_APP_API_URL + "Identity/";
  const endpoint = "token";

  const requestBody = {
    email: dto.email,
    password: dto.password,
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify(requestBody),
  };

  const response: Response = await fetch(
    identityroute + endpoint,
    requestOptions
  );

  if (!response.ok) {
    throw new Error(`Failed to fetch token: ${response.status}}`);
  }

  const token: ResToken = (await response.json()) as ResToken;
  return token;
}
